import React, { Component } from 'react'
import { connect } from 'react-redux'
import {signinClose} from '../../redux/modules/modal'

import SignIn from './forms/sign-in'

class Signin extends Component {
  componentDidMount() {
    document.querySelector('body').style = "overflow:hidden"
  }
  componentWillUnmount() {
    document.querySelector('body').style = '' 
  }

  render() {
    return (
      <div className={this.props.mobile ? "modal signin-mobile" : "modal signin"}>
        <div className="modal__overlay" onClick={this.props.signinClose}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.signinClose}></div>
            <SignIn small={true} result={this.props.result} />
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile
  }),
  {signinClose}
)(Signin)
