import React from 'react';

const logo = ({mobile}) => {
  return (
    <div className="layer layer_logo">
      <img src={mobile ? "/images/mobile/33.png" : "/images/layers/logo.png"} alt=""/>
      <img className="layer-abs logo_abs1" src="/images/layers/text/1.png" alt=""/>
      <img className="layer-abs logo_abs2" src="/images/layers/text/2.png" alt=""/>
      <img className="layer-abs logo_abs3" src="/images/layers/text/3.png" alt=""/>
      <img className="layer-abs logo_abs4" src="/images/layers/text/4.png" alt=""/>
      <img className="layer-abs logo_abs5" src="/images/layers/text/5.png" alt=""/>
      <img className="layer-abs logo_abs6" src="/images/layers/text/6.png" alt=""/>
      <img className="layer-abs logo_abs7" src="/images/layers/text/7.png" alt=""/>
      <img className="layer-abs logo_abs8" src="/images/layers/text/8.png" alt=""/>
      <img className="layer-abs logo_abs9" src="/images/layers/text/9.png" alt=""/>
      <img className="layer-abs logo_abs10" src="/images/layers/text/10.png" alt=""/>
      <img className="layer-abs logo_abs11" src="/images/layers/text/11.png" alt=""/>
      <img className="layer-abs logo_abs12" src="/images/layers/text/12.png" alt=""/>
      <img className="layer-abs logo_abs13" src="/images/layers/text/13.png" alt=""/>
      <img className="layer-abs logo_abs14" src="/images/layers/text/14.png" alt=""/>

      <img className="layer-abs logo_abs15" src="/images/layers/text/15.png" alt=""/>
      <img className="layer-abs logo_abs16" src="/images/layers/text/16.png" alt=""/>
      <img className="layer-abs logo_abs17" src="/images/layers/text/17.png" alt=""/>
      <img className="layer-abs logo_abs18" src="/images/layers/text/18.png" alt=""/>
      <img className="layer-abs logo_abs19" src="/images/layers/text/19.png" alt=""/>
      <img className="layer-abs logo_abs20" src="/images/layers/text/20.png" alt=""/>
      <img className="layer-abs logo_abs21" src="/images/layers/text/21.png" alt=""/>
      <img className="layer-abs logo_abs22" src="/images/layers/text/22.png" alt=""/>
      <img className="layer-abs logo_abs23" src="/images/layers/text/23.png" alt=""/>
      <img className="layer-abs logo_abs24" src="/images/layers/text/24.png" alt=""/>
      <img className="layer-abs logo_abs25" src="/images/layers/text/25.png" alt=""/>
      <img className="layer-abs logo_abs26" src="/images/layers/text/26.png" alt=""/>
      <img className="layer-abs logo_abs27" src="/images/layers/text/27.png" alt=""/>
      <img className="layer-abs logo_abs28" src="/images/layers/text/28.png" alt=""/>
      <img className="layer-abs logo_abs29" src="/images/layers/text/29.png" alt=""/>
      <img className="layer-abs logo_abs30" src="/images/layers/text/30.png" alt=""/>
      <img className="layer-abs logo_abs31" src="/images/layers/text/31.png" alt=""/>
      <img className="layer-abs logo_abs32" src="/images/layers/text/32.png" alt=""/>
      <img className="layer-abs logo_abs33" src="/images/layers/text/33.png" alt=""/>
    </div>
  )
}

export default logo;