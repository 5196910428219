export const api = window.location.hostname === 'localhost' ? 'http://x5trade2020.multicontent.ru/ajax/' : '/ajax/'
export const api2 = window.location.hostname === 'localhost' ? 'http://x5trade2020.multicontent.ru/' : ''

export const images = [
  '/images/step2/cursor.png',
  '/images/spacer.png',
  '/images/track.png',
  '/images/scroller-bottom.png',
  '/images/scroller-top.png',
  '/images/scroller.png',
  '/images/bg.jpg',
  '/images/logo.svg',
  '/images/menu-icon.svg',
  '/images/loader.svg',
  '/images/spacer.png',
  '/images/close-menu.svg',

  '/images/modal/icon.png',
  '/images/modal/robot.png',
  '/images/modal/rating-title.png',
  '/images/modal/rating-btn.png',
  '/images/modal/rating.png',
  '/images/modal/send.png',
  '/images/modal/postcard-title.png',
  '/images/modal/close.svg',
  '/images/modal/postcard-image.png',
  '/images/modal/postcard.png',
  '/images/modal/slider/6.png',
  '/images/modal/slider/7.png',
  '/images/modal/slider/8.png',
  '/images/modal/slider/9.png',

  '/images/dishes/i5.png',
  '/images/dishes/i4.png',
  '/images/dishes/i6.png',
  '/images/dishes/i3.png',
  '/images/dishes/i2.png',
  '/images/dishes/i1.png',
  '/images/dishes/i5-2.png',
  '/images/dishes/i4-2.png',
  '/images/dishes/i6-2.png',
  '/images/dishes/i3-2.png',
  '/images/dishes/i2-2.png',
  '/images/dishes/i1-2.png',

  '/images/icons/p3.svg',
  '/images/icons/p2.svg',
  '/images/icons/p1.svg',
  '/images/icons/p5.svg',
  '/images/icons/p4.svg',
  '/images/icons/p6.svg',
  '/images/icons/p8.svg',
  '/images/icons/p9.svg',
  '/images/icons/p7.svg',
  '/images/btn/4.svg',
  '/images/btn/btn2.svg',
  '/images/btn/btn3.svg',
  '/images/btn/5.svg',
  '/images/btn/6.svg',
  '/images/btn/2.svg',
  '/images/btn/3.svg',
  '/images/btn/1.svg',
  '/images/btn/btn.svg',


  '/images/layers/table.png',
  '/images/layers/knife.png',
  '/images/layers/arrow-left.png',
  '/images/layers/logo.png',
  '/images/layers/cheese.png',
  '/images/layers/bubble.png',
  '/images/layers/arrow-right.png',
  '/images/layers/libra.png',

  '/images/saler/1.png',
  '/images/saler/eyes.png',
  '/images/saler/head-shadow.png',
  '/images/saler/saler-hand.png',
  '/images/saler/head.png',
  '/images/saler/e4.png',
  '/images/saler/legs.png',
  '/images/saler/e1.png',
  '/images/saler/e3.png',
  '/images/saler/e2.png',
  '/images/saler/hand.png',
  '/images/saler/body.png',
  '/images/saler/head-bottom.png',
  '/images/saler/saler.png',
  '/images/saler/head-top.png',

  '/images/layers/text/1.png',
  '/images/layers/text/3.png',
  '/images/layers/text/2.png',
  '/images/layers/text/6.png',
  '/images/layers/text/7.png',
  '/images/layers/text/5.png',
  '/images/layers/text/4.png',
  '/images/layers/text/19.png',
  '/images/layers/text/31.png',
  '/images/layers/text/25.png',
  '/images/layers/text/24.png',
  '/images/layers/text/30.png',
  '/images/layers/text/18.png',
  '/images/layers/text/26.png',
  '/images/layers/text/32.png',
  '/images/layers/text/33.png',
  '/images/layers/text/27.png',
  '/images/layers/text/23.png',
  '/images/layers/text/22.png',
  '/images/layers/text/20.png',
  '/images/layers/text/21.png',
  '/images/layers/text/10.png',
  '/images/layers/text/11.png',
  '/images/layers/text/13.png',
  '/images/layers/text/12.png',
  '/images/layers/text/16.png',
  '/images/layers/text/17.png',
  '/images/layers/text/15.png',
  '/images/layers/text/29.png',
  '/images/layers/text/28.png',
  '/images/layers/text/14.png',
  '/images/layers/text/9.png',
  '/images/layers/text/8.png',
  '/images/step2/cursor.svg',
  '/images/step2/cursor.png',
  '/images/step2/saler/6.png',
  '/images/step2/saler/7.png',
  '/images/step2/saler/8.png',
  '/images/step2/saler/9.png',
  '/images/step2/bubble/btn.png',
  '/images/step2/bubble/bubble.png',
  '/images/step2/bubble/6.png',
  '/images/step2/bubble/5.png',
  '/images/step2/bubble/4.png',
  '/images/step2/bubble/3.png',
  '/images/step2/bubble/2.png',
  '/images/step2/bubble/1.png',

  '/images/step4/btn2.png',
  '/images/step4/bubble2.png?12',
  '/images/step4/line-icon.svg',
  '/images/step4/robot-line.svg',
  '/images/step4/robot-line.png',
  '/images/step4/line.svg',
  '/images/step4/line.png',
  '/images/step4/robot-hand.png',
  '/images/step4/robot.png',
  '/images/step4/btn.png',
  '/images/step4/bubble.png',
  
  '/images/auth/5.png',
  '/images/auth/btn12.png',
  '/images/auth/select-arrow.svg',
  '/images/auth/check.svg',
  '/images/auth/btn11.png',
  '/images/auth/btn10.png',
  '/images/auth/btn9.png',
  '/images/auth/icon2.png',
  '/images/auth/icon1.png',
  '/images/auth/btn8.png',
  '/images/auth/id-icon2.svg',
  '/images/auth/4.png',
  '/images/auth/btn7.png',
  '/images/auth/7.png',
  '/images/auth/btn6.png',
  '/images/auth/btn5.png',
  '/images/auth/btn4.png',
  '/images/auth/3.png',
  '/images/auth/id-icon.svg',
  '/images/auth/2.png',
  '/images/auth/btn3.png',
  '/images/auth/close.svg',
  '/images/auth/btn2.png',
  '/images/auth/1.png',
  '/images/auth/btn1.png',

  '/images/paper/1.png',
  '/images/paper/2.png',
  '/images/paper/3.png',
  '/images/paper/4.png',
  '/images/paper/5.png',
  '/images/paper/6.png',

  '/images/paper/i1.png',
  '/images/paper/i2.png',
  '/images/paper/i3.png',
  '/images/paper/i4.png',
  '/images/paper/i5.png',
  '/images/paper/i6.png',


   '/images/mobile/rating.png',
   '/images/mobile/cookie.png',
   '/images/mobile/gameover-img.png',
   '/images/mobile/gameover.png',
   '/images/mobile/signin-img.png',
   '/images/mobile/signin.png',
   '/images/mobile/signup-img.png',
   '/images/mobile/signup.png',
   '/images/mobile/rating-title.png',
   '/images/mobile/reg-bg.png',
   '/images/mobile/postcard-image.png',
   '/images/mobile/postcard.png',
   '/images/mobile/bubble3.png',
   '/images/mobile/bubble2.png',
   '/images/mobile/bubble.png',
   '/images/mobile/33.png',
   '/images/mobile/spacer.png',

  '/images/modals/r1.png',
  '/images/modals/r2.png',
  '/images/modals/r3.png',
  '/images/modals/r5.png',
  '/images/modals/b1.png',
  '/images/modals/b2.png',
  '/images/modals/b3.png',
  '/images/modals/b4.png',
  '/images/modals/b5.png',
  '/images/modals/r1mob.png',
  '/images/modals/r2mob.png',
  '/images/modals/r3mob.png',
  '/images/modals/b1mob.png',
  '/images/modals/b2mob.png',
  '/images/modals/b3mob.png',
  '/images/modals/b4mob.png',
  '/images/modals/b5mob.png',
  '/images/step4/btn2-result.png',
  '/images/step2/bubble/bubblei6.png',
  '/images/step2/bubble/bubblei6mob.png',

]