import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {menuClose, postcardOpen, ratingOpen} from '../../redux/modules/modal'
import {logOut} from '../../redux/modules/sign-in'

import SignIn from './forms/sign-in'

class Menu extends Component {
  componentDidMount() {
    document.querySelector('body').style = "overflow:hidden"
  }
  componentWillUnmount() {
    document.querySelector('body').style = '' 
  }

  render() {
    if (this.props.mobile) return this.mobile
    return (
      <Fragment>
        <div className="modal__overlay modal__overlay_menu" onClick={this.props.menuClose}></div>
        <div className="menu">
          <div className="modal__body">
            <div className="menu__body">
              {this.props.user ? this.profile : <SignIn menu={true} />}
              <div className="menu__icon_1">
                <div className="menu__icon_1__btn" onClick={this.handleRating} />
              </div>
              <div className="menu__icon_2">
                <div className="menu__icon_2__btn" onClick={this.handlePostcard} />
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </Fragment>
    )
  }

  get mobile() {
    return (
      <div className="modal menu-mobile">
        <div className="modal__overlay" onClick={this.props.menuClose} />
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.menuClose}></div>
            {this.props.user ? this.profile : <SignIn menu={true} />}
            <div className="menu-icons-mobile">
              <div className="menu__icon_1">
                <div className="menu__icon_1__btn" onClick={this.handleRating} />
              </div>
              <div className="menu__icon_2">
                <div className="menu__icon_2__btn" onClick={this.handlePostcard} />
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  get profile() {
    const { user } = this.props
    return (
        <div className="profile">
          <span>
            {user.surname ? user.surname : user.lastName} <br /> {user.name}
          </span>{' '}
          <br />
          <div className="lnk" onClick={this.props.logOut}>
            Выйти
          </div>
          <div className="share">
            <div>
              С друзьями всегда веселее. <br />
              Не забудьте поделиться с ними ссылкой
              <div className="social social2">
                <a href={`http://vkontakte.ru/share.php?url=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon VKontakte-icon" />
                <a href={`http://www.facebook.com/sharer.php?s=100&p[url]=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon Facebook-icon" />
                <a href={`https://connect.ok.ru/offer?url=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon Odnoklassniki-icon" />
              </div>
            </div>
          </div>
        </div>
    )
  }

  handlePostcard = () => {
    this.props.menuClose()
    this.props.postcardOpen()
  }

  handleRating = () => {
    this.props.menuClose()
    this.props.ratingOpen()
  }

  // handlePlay = () => {
  //   if (!this.props.step) console.log(1)
  //   if (this.props.step < 4) return false
  //   this.props.menuClose()
  //   this.props.play()
  // }

  
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    user: state.auth.user
  }),
  {menuClose, postcardOpen, logOut, ratingOpen}
)(Menu)
