import React, { Component } from 'react'

class Cookie extends Component {
  state = {
    open: true
  }

  render() {
    if (window.localStorage.getItem('x5cookieInfo') || !this.state.open) return null
    return (
      <div className="cookie_message">
        <p>
          Сайт happy.x5.ru использует Cookie, <br/>
          чтобы сделать пользование сайтом проще. <span onClick={this.props.cookieOpen}>Что это значит?</span>
        </p>
        <div className="cookie_message__close" onClick={this.close}></div>
      </div>
    )
  }

  close = () => {
    window.localStorage.setItem('x5cookieInfo', '1')
    this.setState({ open: false })
  }
}

export default Cookie
