import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {whreDoYouWorkClose} from '../../redux/modules/modal'
import {sendWhereDoYouWork, getPlaceData} from '../../redux/modules/sign-in'
import InputField from '../common/input-field'
import SelectField from '../common/select-field'

import SignIn from './forms/sign-in'

class WhereDoYouWork extends Component {
  state = {
    triggerValidate: false,
    UF_NET: {
      value: '',
      name: 'UF_NET',
      placeholder: 'Магазин',
      valid: false
    },
    WORK_POSITION: {
      value: '',
      name: 'WORK_POSITION',
      type: 'text',
      placeholder: 'Должность',
      validate: 'text',
      valid: false
    },
    UF_REGION: {
      value: '',
      name: 'UF_REGION',
      placeholder: 'Регион',
      valid: false
    },
    WORK_CITY: {
      value: '',
      name: 'WORK_CITY',
      type: 'text',
      placeholder: 'Город',
      validate: 'text',
      valid: false
    },
    pending: false
  }

  componentDidMount() {
    document.querySelector('body').style = "overflow:hidden"
    if (!this.props.ufNetOptions.length) this.props.getPlaceData()
  }
  componentWillUnmount() {
    document.querySelector('body').style = '' 
  }

  render() {
    return (
      <div className={this.props.mobile ? 'modal message-mobile': 'modal message'}>
        <div className="modal__overlay" onClick={this.props.whreDoYouWorkClose} />
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.whreDoYouWorkClose}></div>
            <div className="signin__body">
              <div className="work__title">
                Расскажите, где вы работаете
              </div>
              <div className="modal-content">
                <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.UF_REGION} options={this.ufRegionOptions} />
                <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.WORK_CITY} />
                <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.UF_NET} options={this.ufNetOptions} />
                <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.WORK_POSITION} />
                <div onClick={this.handleSend} className="signin__btn signin__btn2" />
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>

    )
  }

  get ufNetOptions() {
    const options = this.props.ufNetOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
    return [
      <option key={0} value="">
        {this.state.UF_NET.placeholder}
      </option>,
      ...options
    ]
  }

  get ufRegionOptions() {
    const options = this.props.ufRegionOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
    return [
      <option key={0} value="">
        {this.state.UF_REGION.placeholder}
      </option>,
      ...options
    ]
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid() || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('UF_EMPLOYEE', 1)
    formData.append('UF_SOCSERV_FINISHED', 1)

    formData.append('WORK_POSITION', this.state.WORK_POSITION.value)
    formData.append('UF_REGION', this.state.UF_REGION.value)
    formData.append('WORK_CITY', this.state.WORK_CITY.value)
    await this.props.sendWhereDoYouWork(formData, this.props.result)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state).every((name) => {
      const field = this.state[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({ [name]: { ...this.state[name], value } })
  }
  
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    ufNetOptions: state.auth.place_data.net,
    ufRegionOptions: state.auth.place_data.region,
    result: state.modal.result,
  }),
  {whreDoYouWorkClose, sendWhereDoYouWork, getPlaceData}
)(WhereDoYouWork)
