import { push, goBack } from 'connected-react-router'
/**
 * Constants
 * */
export const moduleName = 'loading'

export const LOADING = `${moduleName}/LOADING`

/**
 * Reducer
 * */

export default (state = true, action) => {
  const { type } = action

  switch (type) {
    case LOADING:
      return false

    default:
      return state
  }
}


/**
 * Action Creators
 * */

export const setLoading = (field) => ({ type: LOADING, payload: {field} })

export const getPage = (url) => (dispatch) => dispatch(push(url))

export const getBack = () => (dispatch) => dispatch(goBack())

