import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import {postcardClose} from '../../redux/modules/modal'
import {sendPostcard} from '../../redux/modules/sign-in'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
const textValues = [
  'Поздравляю вас с праздником! Желаю надёжных поставщиков, точных весов и вежливых клиентов!',
  'Пусть весы никогда не подводят, клиенты радуют довольными улыбками, а работа доставляет удовольствие и вдохновляет.',
  'Поздравляю с днём работника торговли! Желаю вам отличного настроения, исполнительных поставщиков и довольных клиентов!',
  'Поздравляю вас с праздником! Желаю надёжных поставщиков, точных весов и вежливых клиентов!',
]

class Postcard extends Component {
  state = {
    triggerValidate: false,
    visible: false,
    pending: false,
    fields: {
      name_to: {
        value: '',
        name: 'name_to',
        type: 'text',
        validate: 'text',
        valid: false
      },
      email: {
        value: '',
        name: 'email',
        type: 'text',
        validate: 'email',
        valid: false
      },
      text: {
        value: textValues[Math.floor(Math.random() * 3)],
        name: 'text',
        type: 'textarea',
        validate: 'text',
        valid: true
      },
      name_from: {
        value: '',
        name: 'name_from',
        type: 'text',
        validate: 'text',
        valid: false
      }
    },
    num: 6
  }

  net = [7,9,6,8]

  componentDidMount() {
    document.querySelector('body').style = "overflow:hidden"
    const {user} = this.props
    if (this.props.user) {
      this.setState({
        fields: {
          ...this.state.fields, 
          name_from: {...this.state.fields.name_from, value:this.props.user.name, valid: true},
        },
        num: user.net ? user.net : 6
      })
    }
  }
  componentWillUnmount() {
    document.querySelector('body').style = ''
    
  }

  componentDidUpdate(prevProps, prevState) {
    const { success, error } = this.props
    if ((success && prevProps.success !== success) || (error && prevProps.error !== error)) {
      this.props.postcardClose()
    }
  }

  render() {
    const settings = {
      initialSlide: this.net.indexOf(this.state.num),
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => this.setState({num: this.net[index]})
    };

    return (
      <div className={this.props.mobile ? 'modal postcard-mobile': 'modal postcard'}>
        <div className="modal__overlay" onClick={this.props.postcardClose}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.postcardClose}></div>
            <div className="postcard__title" />
            <div className="postcard__image" />
            <div className="postcard-slider">
            <Slider ref={slider => (this.slider = slider)} {...settings}>
              <div className="postcard-slider__item postcard-slider__item1" ></div>
              <div className="postcard-slider__item postcard-slider__item2" ></div>
              <div className="postcard-slider__item postcard-slider__item3" ></div>
              <div className="postcard-slider__item postcard-slider__item4" ></div>
            </Slider>
            </div>
            <div className="form">
              <div className="field-title">Кому</div>
              <InputField
                triggerValidate={this.state.triggerValidate}
                setFieldValid={this.setFieldValid}
                change={this.handleChange}
                {...this.state.fields.name_to}
              />
              <div className="field-title">E-mail</div>
              <InputField
                triggerValidate={this.state.triggerValidate}
                setFieldValid={this.setFieldValid}
                change={this.handleChange}
                {...this.state.fields.email}
              />
              <div className="field-title">Текст</div>
              <InputField
                triggerValidate={this.state.triggerValidate}
                setFieldValid={this.setFieldValid}
                change={this.handleChange}
                {...this.state.fields.text}
              />
              <div className="field-title">От кого</div>
              <InputField
                triggerValidate={this.state.triggerValidate}
                setFieldValid={this.setFieldValid}
                change={this.handleChange}
                {...this.state.fields.name_from}
              />
            </div>
            <div className="postcard__btn" onClick={this.handleSend}></div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid() || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('email', this.state.fields.email.value)
    formData.append('name_to', this.state.fields.name_to.value)
    formData.append('name_from', this.state.fields.name_from.value)
    formData.append('text', this.state.fields.text.value)
    formData.append('image', this.state.num)
    this.props.sendPostcard(formData)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state.fields).every((name) => {
      const field = this.state.fields[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: { ...this.state.fields[name], valid }
      }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    if (value.length > 120) return false
    this.setState({
      fields: { ...this.state.fields, [name]: { ...this.state.fields[name], value } }
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    user: state.auth.user,
    ufNetOptions: state.auth.place_data.net
  }),
  {postcardClose, sendPostcard}
)(Postcard)
