/**
 * Constants
 * */
export const moduleName = 'portrait'
export const PORTRAIT = `${moduleName}/PORTRAIT`

/**
 * Reducer
 * */
export default (portrait = false, action) => {
  return action.type === PORTRAIT ? action.portrait : portrait
}

/**
 * Action Creators
 * */
export const setPortrait = (portrait) => {
  return {
    type: PORTRAIT,
    portrait
  }
}
