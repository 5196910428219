import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {gameoverClose, ratingOpen, signinOpen, signupOpen} from '../../redux/modules/modal'
import { sendData } from '../../redux/modules/sign-in'

class GameOver extends Component {
  componentDidMount() {
    document.querySelector('body').style = "overflow:hidden"
    if (this.props.user) {
      this.props.sendData(this.props.result, this.props.user.id)
    }
  }
  componentWillUnmount() {
    document.querySelector('body').style = '' 
  }
  
  render() {
    return (
      <div className="modal gameover">
        <div className="modal__overlay" onClick={this.props.gameoverClose}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.gameoverClose}></div>
            <div className="gameover__title">
              У тебя отлично<br/>получилось!
              <div>Точность:  <span>{this.props.result}%</span></div>
            </div>
            {this.props.user ? 
              <div className="gameover__btn">
                <div className="gameover__btn__btn3" onClick={this.openRating} />
              </div>
              :
              <Fragment>
                <div className="gameover__text">
                  Чтобы поучаствовать в <span onClick={this.openRating}>рейтинге</span>,<br/>
                  и все увидели насколько ты крут,<br/>
                  тебе необходимо:
                </div>
                <div className="gameover__btn">
                  <div className="gameover__btn__btn1" onClick={this.openSignIn} />
                  <span>или</span>
                  <div className="gameover__btn__btn2" onClick={this.openSignUp} />
                </div>
              </Fragment>
            }
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  openSignIn = () => {
    this.props.gameoverClose()
    this.props.signinOpen()
  }

  openSignUp = () => {
    this.props.gameoverClose()
    this.props.signupOpen()
  }
  openRating = () => {
    this.props.gameoverClose()
    this.props.ratingOpen()
  }
}

export default connect(
  (state) => ({
    result: state.modal.result,
    user: state.auth.user
  }),
  {gameoverClose, ratingOpen, signinOpen,signupOpen, sendData}
)(GameOver)
