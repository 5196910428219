import React, { Fragment } from 'react'
import Footer from '../components/layout/footer'

export default (OriginalComponent) =>
  class LayoutComponent extends React.Component {
    componentDidMount() {
      window.scrollTo(0,0)
    }
    render() {
      return (
        <Fragment>
          <OriginalComponent {...this.props} />
          <Footer />
        </Fragment>
      )
    }
  }
