import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {menuClose} from '../../redux/modules/modal'
import {sendSignUp, getPlaceData} from '../../redux/modules/sign-in'
import InputField from '../common/input-field'
import SelectField from '../common/select-field'

import SignIn from './forms/sign-in'

class Menu extends Component {
  state = {
    triggerValidate: false,
    fields: {
      'REGISTER[EMAIL]': {
        value: '',
        name: 'REGISTER[EMAIL]',
        type: 'name',
        placeholder: 'Логин (e-mail)',
        validate: 'email',
        valid: false
      },
      'REGISTER[PASSWORD]': {
        value: '',
        name: 'REGISTER[PASSWORD]',
        type: 'password',
        placeholder: 'Пароль',
        validate: 'password',
        valid: false
      },
      'REGISTER[CONFIRM_PASSWORD]': {
        value: '',
        name: 'REGISTER[CONFIRM_PASSWORD]',
        type: 'password',
        placeholder: 'Подтверждение пароля',
        validate: 'password',
        valid: false,
        compare: 'REGISTER[PASSWORD]'
      },
      'REGISTER[NAME]': {
        value: '',
        name: 'REGISTER[NAME]',
        type: 'text',
        placeholder: 'Имя',
        validate: 'text',
        valid: false
      },
      'REGISTER[LAST_NAME]': {
        value: '',
        name: 'REGISTER[LAST_NAME]',
        type: 'text',
        placeholder: 'Фамилия',
        validate: 'text',
        valid: false
      }
    },
    fields2: {
      UF_NET: {
        value: '',
        name: 'UF_NET',
        placeholder: 'Магазин',
        valid: true
      },
      'REGISTER[WORK_POSITION]': {
        value: '',
        name: 'REGISTER[WORK_POSITION]',
        type: 'text',
        placeholder: 'Должность',
        validate: 'text',
        valid: false
      },
      UF_REGION: {
        value: '',
        name: 'UF_REGION',
        placeholder: 'Регион',
        valid: false
      },
      'REGISTER[WORK_CITY]': {
        value: '',
        name: 'REGISTER[WORK_CITY]',
        type: 'text',
        placeholder: 'Город',
        validate: 'text',
        valid: false
      }
    },
    type: true,
    pending: false
  }

  componentDidMount() {
    document.querySelector('body').style = "overflow:hidden"
    if (!this.props.ufNetOptions.length) this.props.getPlaceData()
  }
  componentWillUnmount() {
    document.querySelector('body').style = '' 
  }

  render() {
    if (this.props.mobile) return this.mobile
    return (
      <Fragment>
        <div className="modal__overlay modal__overlay_menu" onClick={this.props.menuClose}></div>
        <div className="signup">
          <div className="modal__body">
            {this.body}
          </div>
          <div className="modal-helper"></div>
        </div>
      </Fragment>
    )
  }

  get mobile() {
    return (
      <div className="modal signup-mobile">
        <div className="modal__overlay" onClick={this.props.menuClose} />
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.menuClose}></div>
            {this.body}
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  get body() {
    return (
      <div className="signup__body">
        <div className="signup__title">
          <span>Регистрация в</span>
          <span className="id-icon"></span>
        </div>
        <div className="signup__row">
          <InputField
            triggerValidate={this.state.triggerValidate}
            setFieldValid={this.setFieldValid}
            change={this.handleChange}
            compareValue={this.state.fields['REGISTER[LAST_NAME]'].compare ? this.state.fields[this.state.fields['REGISTER[LAST_NAME]'].compare].value : ''}
            {...this.state.fields['REGISTER[LAST_NAME]']}
          />
          <InputField
            triggerValidate={this.state.triggerValidate}
            setFieldValid={this.setFieldValid}
            change={this.handleChange}
            compareValue={this.state.fields['REGISTER[NAME]'].compare ? this.state.fields[this.state.fields['REGISTER[NAME]'].compare].value : ''}
            {...this.state.fields['REGISTER[NAME]']}
          />
          <InputField
            triggerValidate={this.state.triggerValidate}
            setFieldValid={this.setFieldValid}
            change={this.handleChange}
            compareValue={this.state.fields['REGISTER[EMAIL]'].compare ? this.state.fields[this.state.fields['REGISTER[EMAIL]'].compare].value : ''}
            {...this.state.fields['REGISTER[EMAIL]']}
          />
        </div>
        <div className="signup__row">
          <InputField
            triggerValidate={this.state.triggerValidate}
            setFieldValid={this.setFieldValid}
            change={this.handleChange}
            compareValue={this.state.fields['REGISTER[PASSWORD]'].compare ? this.state.fields[this.state.fields['REGISTER[PASSWORD]'].compare].value : ''}
            {...this.state.fields['REGISTER[PASSWORD]']}
          />
          <InputField
            triggerValidate={this.state.triggerValidate}
            setFieldValid={this.setFieldValid}
            change={this.handleChange}
            compareValue={this.state.fields['REGISTER[CONFIRM_PASSWORD]'].compare ? this.state.fields[this.state.fields['REGISTER[CONFIRM_PASSWORD]'].compare].value : ''}
            {...this.state.fields['REGISTER[CONFIRM_PASSWORD]']}
          />
        </div>
        <div className="signup__row">
          <div className="input-field check-field">
            <label>
              <input type="checkbox" name="type" onChange={this.handleChangeCheckbox} checked={this.state.type} />
              <i></i>
              <span>Я работаю в х5</span>
            </label>
          </div>
          {this.state.type && (
            <Fragment>
              <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2.UF_REGION} options={this.ufRegionOptions} />
              <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2['REGISTER[WORK_CITY]']} />
            </Fragment>
          )}
        </div>
        <div className="signup__row">
          {this.state.type && (
            <Fragment>  
              <div></div>
              <SelectField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2.UF_NET} options={this.ufNetOptions} />
              <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid2} change={this.handleChange2} {...this.state.fields2['REGISTER[WORK_POSITION]']} />
            </Fragment>
          )}
        </div>
        <div onClick={this.handleSend} className="signup__btn" />
        
      </div>
    )
  }

  get ufNetOptions() {
    return this.props.ufNetOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
  }

  get ufRegionOptions() {
    const options = this.props.ufRegionOptions.map((el) => (
      <option key={el.id} value={el.id}>
        {el.value}
      </option>
    ))
    return [
      <option key={0} value="">
        {this.state.fields2.UF_REGION.placeholder}
      </option>,
      ...options
    ]
  }

  fields() {
    const { fields } = this.state
    return Object.keys(fields).map((el, i) => {
      console.log(fields)
      const field = fields[el]
      return <InputField compareValue={field.compare ? fields[field.compare].value : ''} key={i} triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...field} />
    })
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid() || this.state.pending || (this.state.type && !this.formIsValid2())) return false
    this.setState({ pending: true })

    const formData = new FormData()
    Object.keys(this.state.fields).map((el) => {
      const field = this.state.fields[el]
      formData.append(field.name, field.value)
    })

    formData.append('REGISTER[AUTO_TIME_ZONE]', 'Y')
    formData.append('register_submit_button', 'Y')
    formData.append('UF_EMPLOYEE', this.state.type)
    formData.append('UF_SOCSERV_FINISHED', 0)

    if (this.state.type) {
      Object.keys(this.state.fields2).map((el) => {
        const field = this.state.fields2[el]
        formData.append(field.name, field.value)
      })
    }

    //formData.append('REGISTER_FILES_PERSONAL_PHOTO', this.state.REGISTER_FILES_PERSONAL_PHOTO)
    // formData.append('PERSONAL_PHOTO_DATA', this.state.PERSONAL_PHOTO_DATA)

    // const config = { headers: { 'content-type': 'multipart/form-data' } }

    this.props.sendSignUp(formData, this.props.result)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state.fields).every((name) => {
      const field = this.state.fields[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  formIsValid2 = () => {
    return Object.keys(this.state.fields2).every((name) => {
      const field = this.state.fields2[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: { ...this.state.fields[name], valid }
      }
    })

  setFieldValid2 = (name, valid) =>
    this.setState({
      fields2: {
        ...this.state.fields2,
        [name]: { ...this.state.fields2[name], valid }
      }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({
      fields: { ...this.state.fields, [name]: { ...this.state.fields[name], value } }
    })
  }

  handleChange2 = (ev) => {
    const { name, value } = ev.target
    this.setState({
      fields2: { ...this.state.fields2, [name]: { ...this.state.fields2[name], value } }
    })
  }

  handleChangeCheckbox = (ev) => {
    const { name } = ev.target
    this.setState({ [name]: this.state.type ? false : true })
  }
  
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    ufNetOptions: state.auth.place_data.net,
    ufRegionOptions: state.auth.place_data.region,
    result: state.modal.result,

  }),
  {menuClose, sendSignUp, getPlaceData}
)(Menu)
