import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { images } from '../../redux/config'
import { setLoading } from '../../redux/modules/loading'

class Loader extends Component {
  static propTypes = {
    setLoading: PropTypes.func.isRequired
  }

  state = {
    loadedImages: 0,
    percent: 0
  }


  componentDidMount() {

    images.map((picture) => {
      const img = new Image()
      img.src = picture
      img.onload = () => this.setState({ loadedImages: this.state.loadedImages + 1, percent: this.state.loadedImages*100/images.length })
      img.onerror = () => {
        throw picture + ' not loaded'
      }
    })


  }

  componentDidUpdate(prevProps, prevState) {
    const { loadedImages } = this.state

    if (loadedImages === images.length && loadedImages !== prevState.loadedImages) {
      this.props.setLoading()
    }
  }

  render() {
    // if (this.props.loading && !this.props.nextStepLoader) return <div className="loading" />
    return <div className="loading"><span><i>{Math.round(this.state.percent)}</i></span></div>
  }
}


export default connect(
  null,
  { setLoading }
)(Loader)