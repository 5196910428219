import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

class Modals extends Component {
  render() {
    const modal =  Math.floor(Math.random() * 5) + 1
    return (
      <div className="modal-robot" >
        {modal === 1 && <div className="spacer spacer-right-bottom">
          {this.img}
          <div className="layer r1">
            <img src={`/images/modals/r1${this.props.portrait ? 'mob' : ''}.png`} alt=""/>
          </div>  
          <div className="layer b1">
            <img src={`/images/modals/b1${this.props.portrait ? 'mob' : ''}.png`} alt=""/>
          </div>    
        </div>}
        {modal === 2 && <div className="spacer spacer-left-bottom">
          {this.img}
          <div className="layer r2">
            <img src={`/images/modals/r2${this.props.portrait ? 'mob' : ''}.png`} alt=""/>
          </div>  
          <div className="layer b2">
            <img src={`/images/modals/b2${this.props.portrait ? 'mob' : ''}.png`} alt=""/>
          </div>    
        </div>}
        {modal === 3 && <div className="spacer spacer-right-top">
          {this.img}
          <div className="layer r3">
            <img src={`/images/modals/r3${this.props.portrait ? 'mob' : ''}.png`} alt=""/>
          </div>  
          <div className="layer b3">
            <img src={`/images/modals/b3${this.props.portrait ? 'mob' : ''}.png`} alt=""/>
          </div>    
        </div>}
        {modal === 4 && !this.props.portrait && <div className="spacer spacer-left-bottom">
          {this.img}
          <div className="layer r2">
            <img src={`/images/modals/r2.png`} alt=""/>
          </div>  
          <div className="layer b2">
            <img src={`/images/modals/b4.png`} alt=""/>
          </div>    
        </div>}
        {modal === 4 && this.props.portrait && <div className="spacer spacer-right-bottom">
          {this.img}
          <div className="layer r1">
            <img src={`/images/modals/r1mob.png`} alt=""/>
          </div>  
          <div className="layer b4">
            <img src={`/images/modals/b4mob.png`} alt=""/>
          </div>    
        </div>}
        {modal === 5 && !this.props.portrait && <div className="spacer spacer-left-top">
          {this.img}
          <div className="layer b5">
            <img src={`/images/modals/b5.png`} alt=""/>
          </div>   
          <div className="layer r5">
            <img src={`/images/modals/r5.png`} alt=""/>
          </div>   
        </div>}
        {modal === 5 && this.props.portrait && <div className="spacer spacer-left-bottom">
          {this.img}
          <div className="layer b5">
            <img src={`/images/modals/b5mob.png`} alt=""/>
          </div>   
          <div className="layer r2">
            <img src={`/images/modals/r2mob.png`} alt=""/>
          </div>   
        </div>}
      </div>
    )
  }

  get img() {
    const { portrait, mobile, height } = this.props
    return <img style={portrait ? {maxHeight: height} : {}} src={portrait ? '/images/mobile/spacer.png' : '/images/spacer.png'} alt=""/>
  }
}

export default connect(
  (state) => ({
    portrait: state.portrait,
    mobile: state.mobile,
    height: state.height,
  }),
  {}
)(Modals)
