/**
 * Constants
 * */
export const moduleName = 'modal'

export const POLICY = `${moduleName}/POLICY`
export const RATING = `${moduleName}/RATING`
export const GAMEOVER = `${moduleName}/GAMEOVER`
export const SIGNIN = `${moduleName}/SIGNIN`
export const ERROR = `${moduleName}/ERROR`
export const FORGOT_PASSWORD = `${moduleName}/FORGOT_PASSWORD`
export const MENU = `${moduleName}/MENU`
export const SIGNUP = `${moduleName}/SIGNUP`
export const WHERE_DO_YOU_WORK = 'access/WHERE_DO_YOU_WORK'
export const POSTCARD = `${moduleName}/POSTCARD`
export const COOKIE = `${moduleName}/COOKIE`

export const SUCCESS = `_SUCCESS`
export const OPEN = `_OPEN`
export const CLOSE = `_CLOSE`

export const SOCIAL_AUTH_1 = 'access/LOGIN_STEP_1'
export const SOCIAL_AUTH_2 = 'access/LOGIN_STEP_2'
export const SOCIAL_AUTH_ERROR = 'access/LOGIN_STEP_ERROR'

/**
 * Reducer
 * */
const defaultState = {
  policy: {
    open: false
  },
  postcard: {
    open: false
  },
  postcard_success: {
    open: false
  },
  rating: {
    open: false,
  },
  gameover: {
    open: false
  },
  signin: {
    open: false
  },
  error: {
    open: false,
    message: null
  },
  forgot_password: {
    open: false
  },
  signin_success: {
    open: false
  },
  signin_step2: {
    open: false
  },
  menu: {
    open: false
  },
  signup: {
    open: false
  },
  signup_success: {
    open: false
  },
  cookie: {
    open: false
  },
  result: 0
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {

    case POLICY + OPEN:
      return {...state, policy: {open: true} }
      
    case POLICY + CLOSE:
      return {...state, policy: {open: false} }

    case POSTCARD + OPEN:
      return {...state, postcard: {open: true} }
      
    case POSTCARD + CLOSE:
      return {...state, postcard: {open: false} }

    case POSTCARD + SUCCESS + OPEN:
      return {...state, postcard_success: {open: true}, postcard: {open: false} }
      
    case POSTCARD + SUCCESS + CLOSE:
      return {...state, postcard_success: {open: false} }

    case RATING + OPEN:
      return {...state, rating: {open: true} }
      
    case RATING + CLOSE:
      return {...state, rating: {open: false} }

    case GAMEOVER + OPEN:
      return {...state, gameover: {open: true}, result: payload.percent }
      
    case GAMEOVER + CLOSE:
      return {...state, gameover: {open: false} }

    case SIGNIN + OPEN:
      return {...state, signin: {open: true} }
      
    case SIGNIN + CLOSE:
      return {...state, signin: {open: false} }

    case SIGNIN + SUCCESS + OPEN:
      return {...state, signin_success: {open: true} }
      
    case SIGNIN + SUCCESS + CLOSE:
      return {...state, signin_success: {open: false} }

    case ERROR + OPEN:
      return { ...state, error: { open: true, message: payload.message } }

    case ERROR + CLOSE:
      return { ...state, error: { open: false, message: '' } }

    case FORGOT_PASSWORD + OPEN:
      return { ...state, forgot_password: { open: true } }

    case FORGOT_PASSWORD + CLOSE:
      return { ...state, forgot_password: { open: false } }

    case MENU + OPEN:
      return {...state, menu: {open: true} }
      
    case MENU + CLOSE:
      return {...state, menu: {open: false}, signup: {open: false} }


    case SOCIAL_AUTH_ERROR: // авторизация не завершена
      closeGlobalSocialWindow()
      console.log('ошибка, авторизация не завершена')
      return { ...state, error: { open: true, message: 'Ошибка авторизации' } }

    case SOCIAL_AUTH_2: // авторизация успешно завершена и закончена
      closeGlobalSocialWindow()
      console.log('авторизация успешно завершена и закончена')
      return { ...state, signin: {open: false}, signin_success: {open: true} }

    case SOCIAL_AUTH_1: // авторизация успешно завершена но не закончена
      closeGlobalSocialWindow()
      console.log('авторизация успешно завершена но не закончена')
      return {...state, signin: {open: false}, signin_step2: { open: true } }


    case SIGNUP + OPEN:
      return {...state, signup: {open: true} }

    case SIGNUP + SUCCESS + OPEN:
      return {...state, signup_success: {open: true}, signup: {open: false} }
      
    case SIGNUP + SUCCESS + CLOSE:
      return {...state, signup_success: {open: false} }

    case WHERE_DO_YOU_WORK + CLOSE:
      return {...state, signin_step2: {open: false}, signin_success: {open: true}}
      
    case COOKIE + OPEN:
      return {...state, cookie: {open: true} }
      
    case COOKIE + CLOSE:
      return {...state, cookie: {open: false} }


    default:
      return state
  }
}


/**
 * Action Creators
 * */

export const postcardOpen = () => ({ type: POSTCARD + OPEN })
export const postcardClose = () => ({ type: POSTCARD + CLOSE })
export const ratingOpen = () => ({ type: RATING + OPEN })
export const ratingClose = () => ({ type: RATING + CLOSE })
export const policyOpen = () => ({ type: POLICY + OPEN })
export const policyClose = () => ({ type: POLICY + CLOSE })
export const gameoverOpen = (percent) => ({ type: GAMEOVER + OPEN, payload:{percent} })
export const gameoverClose = () => ({ type: GAMEOVER + CLOSE })
export const signinOpen = () => ({ type: SIGNIN + OPEN })
export const signinClose = () => ({ type: SIGNIN + CLOSE })
export const closeError = () => ({ type: ERROR + CLOSE })
export const forgotPasswordOpen = () => ({ type: FORGOT_PASSWORD + OPEN })
export const forgotPasswordClose = () => ({ type: FORGOT_PASSWORD + CLOSE })
export const signInSuccessOpen = () => ({ type: SIGNIN + SUCCESS + OPEN })
export const signInSuccessClose = () => ({ type: SIGNIN + SUCCESS + CLOSE })
export const menuOpen = () => ({ type: MENU + OPEN })
export const menuClose = () => ({ type: MENU + CLOSE })
export const signupOpen = () => ({ type: SIGNUP + OPEN })
export const signUpSuccessClose = () => ({ type: SIGNUP + SUCCESS + CLOSE })
export const whreDoYouWorkClose = () => ({ type: WHERE_DO_YOU_WORK + CLOSE })
export const postcardSuccessClose = () => ({ type: POSTCARD + SUCCESS + CLOSE })
export const cookieOpen = () => ({ type: COOKIE + OPEN })
export const cookieClose = () => ({ type: COOKIE + CLOSE })

const closeGlobalSocialWindow = () => {
  if (window.GLOBAL_SOCIAL_WINDOW && typeof window.GLOBAL_SOCIAL_WINDOW.close === 'function') {
    window.GLOBAL_SOCIAL_WINDOW.close()
  }
}