import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Message extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
  }

  state = {
    visible: false
  }

  id = null


  render() {
    const { visible } = this.state
    return (
      <div className={this.props.mobile ? 'modal message-mobile': 'modal message'}>
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.close}></div>
            <div className="message-content">
              <div className="message__title">Ошибка!</div>
              <div className="message__body" dangerouslySetInnerHTML={{ __html: this.props.message }} />
              <div className="message__btn" onClick={this.props.close}></div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }
}


export default connect(
  (state) => ({
    mobile: state.mobile,
  }),
  {}
)(Message)
