import axios from 'axios'
import { api } from '../config'
import { ERROR as ERROR_MESSAGE, SIGNIN, SIGNUP, WHERE_DO_YOU_WORK, POSTCARD } from './modal'
import md5 from 'js-md5'

/**
 * Constants
 * */
export const moduleName = 'auth'

export const CHECKED_SESSION = `${moduleName}/CHECKED_SESSION`
export const SIGN_IN = `${moduleName}/SIGN_IN`
export const GET_LINKS = `${moduleName}/GET_LINKS`
export const FORGOT_PASSWORD = `${moduleName}/FORGOT_PASSWORD`
export const CHANGE_PASSWORD = `${moduleName}/CHANGE_PASSWORD`

export const OPEN = '_OPEN'
export const CLOSE = '_CLOSE'
export const SUCCESS = '_SUCCESS'
export const ERROR = '_ERROR'
export const RESET = '_RESET'
export const GET_PLACE_DATA = `${moduleName}/GET_PLACE_DATA`
export const LOGOUT = `${moduleName}/LOGOUT`
export const SEND = `${moduleName}/SEND`
export const GET_RATING = `${moduleName}/GET_RATING`

/**
 * Reducer
 * */
const defaultState = {
  checkedSession: false,
  user: null,
  links: [],
  forgot_password: {
    error: false,
    success_code: false,
    success: false
  },

  place_data: {
    net: [],
    region: []
  },
  rating: []
}

export default (state = defaultState, action) => {
  const { type, user, error, email, payload } = action

  switch (type) {

    case CHECKED_SESSION: 
      return { ...state, checkedSession: true }

    case SIGN_IN:
      return {...state, checkedSession: true, user }

    case LOGOUT:
      return { ...state, user: null }

    case GET_LINKS:
      return { ...state, links: payload.links }

    case FORGOT_PASSWORD + ERROR:
      return { ...state, forgot_password: {...state.forgot_password, error: payload.message} }

    case FORGOT_PASSWORD + SUCCESS:
      return { ...state, forgot_password: {...state.forgot_password, error: false, success_code: true} }

    case CHANGE_PASSWORD:
      return { ...state, forgot_password: { ...state.forgot_password, error: false, success: true, success_code: false } }

    case FORGOT_PASSWORD + RESET: 
      return { ...state, forgot_password: defaultState.forgot_password }

    case GET_PLACE_DATA:
      return { ...state, place_data: payload.place_data }

    case GET_RATING:
      return { ...state, rating: payload.rating }





    default:
      return state
  }
}


/**
 * Action Creators
 * */

export const checkSession = (user) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-profile.php')
  if (data && !data.authorized) return dispatch({ type: CHECKED_SESSION })
  if (data && data.authorized) {
    await dispatch({ type: SIGN_IN, user: data.user })
    dispatch(getPlaceData())
  }
}
export const getLinks = () => async (dispatch) => {
  const { data } = await axios.post(api + 'socauth-json.php', null, { params: { mode: 'url' } })
  dispatch({ type: GET_LINKS, payload: { links: data } })
}

export const signUp = (user) => async (dispatch) => {
  const params = new FormData();
  params.append('gender', user.gender)
  params.append('name', user.name)
  params.append('parents', user.parents)
  params.append('step', user.step)
  user.userpic.forEach((item) => params.append('userpic[]', item));

  const { data } = await axios.post(api + 'user-registration.php', params)

  if (data.success && data.authorized) dispatch({ type: SIGN_IN, user: {...user, id: data.id} })
  if (!data.success && !data.authorized) console.log('server error')
}

export const signIn = (params, result) => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', params, { params: { service: 'authorization', method: 'login' } })
  if (data.errorCode) return dispatch({ type: ERROR_MESSAGE + OPEN, payload: { message: data.errorMessage } })
    console.log(data.userId);
  if (data.userId) {
    if (result) await dispatch(sendData(result, data.userId))
    dispatch({ type: SIGNIN + SUCCESS + OPEN })
  }
}

export const getProfile = (result) => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', null, { params: { service: 'user', method: 'profileselfget' } })
  if (data.errorCode) return dispatch({ type: ERROR_MESSAGE + OPEN, payload: { message: data.errorMessage } })
  if (result) await dispatch(sendData(result, data.profile.id))
  dispatch({ type: SIGN_IN, user: data.profile })
}

export const forgotPassword = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', params, { params: { service: 'authorization', method: 'passwordrecovery' } })
  if (data.errorCode) return dispatch({ type: FORGOT_PASSWORD + ERROR, payload: { message: data.errorMessage } })
  dispatch({ type: FORGOT_PASSWORD + SUCCESS })
}

export const changePassword = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', params, { params: { service: 'authorization', method: 'passwordchange' } })
  if (data.errorCode) return dispatch({ type: FORGOT_PASSWORD + ERROR, payload: { message: data.errorMessage } })
  dispatch({ type: CHANGE_PASSWORD })
}

export const counterReset = () => ({ type: FORGOT_PASSWORD + RESET })


export const getPlaceData = () => async (dispatch) => {
  const { data } = await axios.post(api + 'jsonapi-endpoint.php', null, { params: { service: 'dictionary', method: 'dictionariesget' } })
  dispatch({ type: GET_PLACE_DATA, payload: { place_data: data } })
}

export const sendSignUp = (params, result) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-registration.php', params)
  if (data.success) {
    dispatch({ type: SIGNUP + SUCCESS + OPEN })
  }
  if (!data.success) dispatch({ type: ERROR_MESSAGE + OPEN, payload: { message: data.error } })
}

export const sendWhereDoYouWork = (params, result) => async (dispatch) => {
  const { data } = await axios.post(api + 'user-save-json.php', params)
  if (data.success) {
    await dispatch(getProfile(result))
    dispatch({ type: WHERE_DO_YOU_WORK + SUCCESS + OPEN })
  }
  if (!data.success) dispatch({ type: ERROR_MESSAGE + OPEN, payload: { message: data.message } })
}

export const logOut = () => async (dispatch) => {
  const { data } = await axios.post(api + 'user-logout.php')
  await dispatch({ type: LOGOUT })
  if (window) window.location.reload()
}

export const sendPostcard = (params) => async (dispatch) => {
  const { data } = await axios.post(api + 'postcard-json.php', params)
  if (data.success) dispatch({ type: POSTCARD + SUCCESS + OPEN })
  if (!data.success) dispatch({ type: ERROR_MESSAGE + OPEN, payload: { message: data.message } })
}

export const sendData = (result, id) => async (dispatch) => {
  const formData = new FormData()
  formData.append('result',  md5(id + '' + result))
  const { data } = await axios.post(api + 'request-json.php', formData)
  if (!data.success) dispatch({ type: ERROR_MESSAGE + OPEN, payload: { message: data.message } })
} 
const compare = (a, b) => (b < a ? -1 : b > a ? 1 : 0)
export const getRating = (params) => async (dispatch) => {
  const { data } = await axios.get(api + 'result-json.php')
  if (data.success) dispatch({ type: GET_RATING, payload: {rating: data.results.sort((a, b) => compare(a['result'], b['result']))}})
  if (!data.success) dispatch({ type: ERROR_MESSAGE + OPEN, payload: { message: data.message } })
} 

