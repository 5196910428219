import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import layout from '../../decorators/layout'
import Logo from './logo'
import Postcard from '../modal/postcard'
import PostcardSuccess from '../modal/postcard-success'
import Rating from '../modal/rating'
import Gameover from '../modal/game-over'
import SignIn from '../modal/sign-in'
import {postcardOpen, ratingOpen, gameoverOpen, closeError, menuOpen, menuClose, cookieOpen, cookieClose} from '../../redux/modules/modal'
import ErrorMessage from '../modal/message'
import ForgotPassword from '../modal/forgot-password'
import SignInSuccess from '../modal/sign-in-success'
import Menu from '../modal/menu'
import Signup from '../modal/sign-up'
import SignUpSuccess from '../modal/sign-up-success'
import WhereDoYouWotk from '../modal/where-do-you-work'
import Cookie from '../modal/cookie'
import DraggableCore from 'react-draggable'
import CookieInfo from '../common/cookie-info'
import Modals from './modals'

class Home extends Component {
  state = {
    moveLeft: true,
    start: false,
    step: 2, //start-2
    slider: ['i1','i2','i3','i4','i5','i6'],
    current: 0,
    newCurrent: 1,
    tableAnimation: null,
    animated: false,
    current_btn: 1,
    play: false,
    game: 1,
    x:50,
    random: 1,
    weight:[400, 520, 630, 360, 440, 440],
    needToСut:[240, 280, 300, 270, 290, 260],
    animateValue: 0,
    game_done: null,
    list: [],
    result: 80,
    ntc:0,
    num: 6,
    initedGame: false,
    showRobotBubble: true,
    ie: false,
    modalShow: false

  }
  tid = null
  t1 = null
  t2 = null
  t3 = null

  componentDidMount() {
    if (this.msieversion()) {
      this.setState({ie: true})
    }

    if (this.props.user) {
      this.setState({
        num: this.props.user.net ? this.props.user.net : 6
      })
    }
    if (this.state.step === 10 ) {
      this.setState({tableAnimation: 3})
      this.t3 = setTimeout(() => {
        this.setWeight()
        this.animateValue(this.state.weight[this.state.current])
      }, 1600)
      return 
    }
    this.tid = setTimeout(() => this.setState({step: 3, tableAnimation: 2}), 6500)
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step && this.state.step === 3) {
      this.tid = setTimeout(() => this.setState({step: 4}), 2500)
    }
    if (prevState.step !== this.state.step && this.state.step === 4) {
      this.tid = setTimeout(() => this.setState({step: 5}), 200)
    }
    if (prevState.step !== this.state.step && this.state.step === 5) {
      this.tid = setTimeout(() => this.setState({step: 6}), 1100)
    }
    if (prevState.step !== this.state.step && this.state.step === 6) {
      this.tid = setTimeout(() => this.setState({eyeAnimate: true}), 7000)
    }
    if (prevState.step !== this.state.step && this.state.step === 10) {
      this.tid = setTimeout(() => {
        this.setWeight()
        this.animateValue(this.state.weight[this.state.newCurrent - 1])
      }, 300)
    }
    if (prevState.game !== this.state.game && this.state.game === 3) {
      this.tid = setTimeout(() => this.nextGameStep(), 1000)
    }

    if (prevState.game !== this.state.game && this.state.game === 4) {
      this.tid = setTimeout(() => {
        this.setWeight()
        this.animateValue(this.state.weight[this.state.newCurrent - 1])
        if (this.state.initedGame) {
          this.nextGameStep()
          this.setState({showRobotBubble: false})
        }
      }, 1000)
    }
    if (this.state.animated && prevState.animated !== this.state.animated) {
      this.t1 = setTimeout(() => this.setState({animated: false}), 1800)
      this.t2 = setTimeout(() => this.setState({current_btn: this.state.newCurrent}), 1500)
    }
    if (this.props.user && this.props.user !== prevProps.user) {
      this.setState({
        num: this.props.user.net ? this.props.user.net : 6
      })
    }

    if (prevState.game !== this.state.game && this.state.game === 5 && this.state.game_done === 'i3' && this.state.list.length > 1) {
      setTimeout(() => {
        this.setState({modalShow: true})
        setTimeout(() => {
          this.setState({modalShow: false})
        }, 3500)
      }, 4100)
    }

    

  }

  setWeight = () => {
    const weight = []
    const needToСut = []
    const list = document.querySelectorAll(`.handle-width-counter`)
    for (var item of list) {
     weight.push(Math.round(item.getBoundingClientRect().width))
     needToСut.push(Math.round(item.getBoundingClientRect().width/2+Math.random() * (50 - 10) + 10))
    }
    this.setState({ weight, needToСut })
  }

  componentWillUnmount() {
    clearTimeout(this.t1)
    clearTimeout(this.t2)
    clearTimeout(this.tid)
    clearTimeout(this.t3)
  }

  handleNext = () => {
    const { tableAnimation, reverse_slider, animated, weight } = this.state
    if (animated) return false
    const current = this.state.current < 6 ? this.state.current + 1 : 1
    const newCurrent = this.state.newCurrent < 6 ? this.state.newCurrent + 1 : 1
    this.setState({
      current,
      newCurrent,
      tableAnimation: tableAnimation === 1 ? 0 : 1,
      animated: true,
      x: 50
    })

    if (this.state.step < 10) return false
    this.setState({game: 1})
    // this.getRandom()
    this.t3 = setTimeout(() => this.animateValue(weight[newCurrent - 1]), 1600)
  }

  render() {
    const { portrait, mobile, height } = this.props
    const { current, newCurrent, tableAnimation, current_btn, play, game, step, game_done, ie } = this.state
    const showTop =  !ie && ((height >= 900 && !portrait) || (height >= 700 && portrait && window.outerWidth < 760))
    const count = 6 - this.state.list.length

    return (
      <Fragment>
        <div className={`page main step-${step} table-animation-${tableAnimation} game-${game} done-${game_done}`} style={portrait ? {height: height} : {}} >
          {this.state.step > 1 && 
            <Fragment>
              {!portrait && 
                <div className="spacer spacer-right">
                  <img  src='/images/spacer.png' alt=""/>
                  {this.state.step === 10 && this.state.game >= 4 &&
                    <div className="layer robot">
                      <img src="/images/step4/robot.png" alt=""/> 
                    </div>
                  }
                </div>
              }
              {showTop && 
                <div className="spacer spacer-top">
                  <img style={portrait ? {maxHeight: height} : {}} src={portrait ? '/images/mobile/spacer.png' : '/images/spacer.png'} alt=""/>
                  <Logo mobile={portrait} /> 
                </div>
              }
              <div className="spacer spacer-middle">
                <img style={portrait ? {maxHeight: height} : {}} src={portrait ? '/images/mobile/spacer.png' : '/images/spacer.png'} alt=""/>
                {!showTop && 
                  <Fragment>
                    <div className="layer logo">
                      <img src="/images/logo.svg" alt=""/>
                    </div>          
                    <div className="layer cookie-lnk" onClick={this.props.cookieClose}>
                      <img src="/images/cookie/btn.png" alt=""/>
                    </div>   
                    <div className="layer menu-icon" onClick={this.toggleMenu}>
                      {(this.props.menu || this.props.signup) && !mobile ? <img src="/images/close-menu.svg" alt=""/> : <img src="/images/menu-icon.svg" alt=""/>}
                    </div>   
                    <div className="layer btn2" onClick={this.props.ratingOpen}>
                      <img src="/images/btn/btn2.svg" alt=""/>
                    </div> 
                    <div className="layer btn3" onClick={this.props.postcardOpen}>
                      <img src="/images/btn/btn3.svg" alt=""/>
                    </div>  
                  </Fragment>
                }
                <div className="layer libra">
                  <img src="/images/layers/libra.png" alt=""/>
                </div> 
                {step === 10 && <span className="libra-value">{this.state.animateValue} </span>}
                {step === 10 && <span className="libra-unit">г</span>}
                <div className="layer saler">
                  <img src="/images/saler/saler.png" alt=""/>
                  <div className="layer legs"><img src="/images/saler/legs.png" alt=""/></div>
                  <div className="layer-abs saler_top">
                    <div className="layer hand"><img src="/images/saler/hand.png" alt=""/></div>

                    <div className="layer head">
                      <img src="/images/saler/head.png" alt=""/>

                      <img className="layer-abs head-rotate" src="/images/saler/head-shadow.png" alt=""/>
                      <img className="layer-abs" src="/images/saler/head-bottom.png" alt=""/>
                      <img className="layer-abs head-rotate" src="/images/saler/head-top.png" alt=""/>

                      <div className={`layer-abs head-rotate ${this.state.eyeAnimate ? 'eye-anim' : ''}`}>
                        <img className="layer-abs e1" src="/images/saler/e1.png" alt=""/>
                        <img className="layer-abs e2" src="/images/saler/e2.png" alt=""/>
                        <img className="layer-abs e3" src="/images/saler/e3.png" alt=""/>
                        <img className="layer-abs e4" src="/images/saler/e4.png" alt=""/>
                      </div>
                    </div>

                    <div className="layer body"><img src="/images/saler/body.png" alt=""/></div>
                  </div>
                </div> 
                <div className="layer table">
                  <img src="/images/layers/table.png" alt=""/>
                </div>  
                {!showTop && <Logo mobile={portrait} />}
                {portrait && this.state.step === 10 && this.state.game >= 4 &&
                  <div className="layer robot">
                    <img src="/images/step4/robot.png" alt=""/> 
                  </div>
                }

                {portrait && <div className="layer knife">
                  <img src="/images/layers/knife.png" alt=""/>
                </div>} 
                {this.slider} 
                {portrait || <div className="layer knife">
                  <img src="/images/layers/knife.png" alt=""/>
                </div>} 


                <div className="layer arrow-left" onClick={this.handleNext}>
                  <img src="/images/layers/arrow-left.png" alt=""/>
                </div>  
                <div className="layer arrow-right" onClick={this.handleNext}>
                  <img src="/images/layers/arrow-right.png" alt=""/>
                </div>  
                {!showTop && <div className="layer bubble">
                  <img src="/images/layers/bubble.png" alt=""/>
                </div>}  
                <div className="layer btn1" onClick={this.handlePlay}>
                  <img src="/images/btn/btn.svg" alt=""/>
                  <img className="layer-abs" src={`/images/btn/${current_btn}.svg`} alt=""/>
                </div>  

                {this.state.step === 10 && 
                    <div className="layer saler2">
                      <img src={`/images/step2/saler/${this.state.num}.png`} alt=""/>
                    </div>
                }
                {!showTop && this.state.step === 10 && this.state.game < 3 &&
                  <div className="layer bubble2">
                    {portrait ? 
                      (newCurrent === 6 ? <img src="/images/step2/bubble/bubblei6mob.png" alt=""/> : <img src="/images/mobile/bubble.png" alt=""/>) :
                      (newCurrent === 6 ? <img src="/images/step2/bubble/bubblei6.png" alt=""/> : <img src="/images/step2/bubble/bubble.png" alt=""/>)
                    }
                    <span className="need-to-cut">{this.state.needToСut[this.state.newCurrent-1]}<span>г</span></span>
                    
                    <div className="layer btn4" onClick={this.nextGameStep}>
                      <img src="/images/step2/bubble/btn.png" alt=""/>
                    </div> 
                  </div>
                }
                {!showTop && this.state.step === 10 && this.state.game >= 4 && this.state.showRobotBubble &&
                  <div className="layer bubble3">
                    {portrait ? <img src="/images/mobile/bubble2.png" alt=""/> : <img src="/images/step4/bubble.png" alt=""/>}
                    <div className="layer btn5" onClick={this.initedGame}>
                      <img src="/images/step4/btn.png" alt=""/>
                    </div> 
                  </div>
                }
                {!showTop && this.state.step === 10 && this.state.game === 5 &&
                  <div className="layer robot-hand">
                    <img src="/images/step4/robot-hand.png" alt=""/> 
                  </div>
                }
                {!showTop && this.state.step === 10 && (this.state.game === 5 || this.state.game === 1) &&
                  <div className="layer bubble4">
                    {this.state.difference > 0 ? <img src="/images/step4/bubble2.png?12" alt=""/> : <img src="/images/step4/bubble3.png" alt=""/> }
                    {count > 0 ? 
                      <div className="layer btn6" onClick={this.handleGameOver}>
                        <img src="/images/step4/btn2.png" alt=""/>
                      </div> :
                      <div className="layer btn6 btn6_last" onClick={this.handleGameOver}>
                        <img src="/images/step4/btn2-result.png" alt=""/>
                      </div>
                    }
                    {this.state.difference > 0 && <div className="bubble-value">{this.state.difference}<span>г</span></div>}
                    {count > 0 && <div className="bubble-value2"><i>{this.declOfNum(count,['остался','осталось','осталось'])}</i>{count}<span>{this.declOfNum(count,['продукт','продукта','продуктов'])}</span></div> }
                  </div>
                }
                {!showTop && this.props.menu && !this.props.mobile && <Menu play={this.handlePlay} step={this.state.step} />}
                {!showTop && this.props.signup && !this.props.mobile && <Signup />}
                {this.props.mobile || <CookieInfo cookieOpen={this.props.cookieOpen} />}
              </div>
              {showTop && 
                <div className="spacer spacer-top">
                  <img style={portrait ? {maxHeight: height} : {}} src={portrait ? '/images/mobile/spacer.png' : '/images/spacer.png'} alt=""/>
                  <div className="layer logo">
                    <img src="/images/logo.svg" alt=""/>
                  </div>          
                  <div className="layer cookie-lnk" onClick={this.props.cookieClose}>
                    <img src="/images/cookie/btn.png" alt=""/>
                  </div>   
                  <div className="layer menu-icon" onClick={this.toggleMenu}>
                    {(this.props.menu || this.props.signup) && !mobile ? <img src="/images/close-menu.svg" alt=""/> : <img src="/images/menu-icon.svg" alt=""/>}
                  </div>   
                  <div className="layer btn2" onClick={this.props.ratingOpen}>
                    <img src="/images/btn/btn2.svg" alt=""/>
                  </div> 
                  <div className="layer btn3" onClick={this.props.postcardOpen}>
                    <img src="/images/btn/btn3.svg" alt=""/>
                  </div> 
                  <div className="layer bubble">
                    <img src="/images/layers/bubble.png" alt=""/>
                  </div>
                  {this.state.step === 10 && this.state.game < 3 &&
                    <div className="layer bubble2">
                      {portrait ? 
                        (newCurrent === 6 ? <img src="/images/step2/bubble/bubblei6mob.png" alt=""/> : <img src="/images/mobile/bubble.png" alt=""/>) :
                        (newCurrent === 6 ? <img src="/images/step2/bubble/bubblei6.png" alt=""/> : <img src="/images/step2/bubble/bubble.png" alt=""/>)
                      }
                      <span className="need-to-cut">{this.state.needToСut[this.state.newCurrent-1]}<span>г</span></span>
                      
                      <div className="layer btn4" onClick={this.nextGameStep}>
                        <img src="/images/step2/bubble/btn.png" alt=""/>
                      </div> 
                    </div>
                  }
                  {this.state.step === 10 && this.state.game >= 4 && this.state.showRobotBubble &&
                    <div className="layer bubble3">
                      {portrait ? <img src="/images/mobile/bubble2.png" alt=""/> : <img src="/images/step4/bubble.png" alt=""/>}
                      <div className="layer btn5" onClick={this.initedGame}>
                        <img src="/images/step4/btn.png" alt=""/>
                      </div> 
                    </div>
                  }
                  {this.state.step === 10 && (this.state.game === 5 || this.state.game === 1) &&
                    <div className="layer bubble4">
                      {this.state.difference > 0 ? <img src="/images/step4/bubble2.png?12" alt=""/> : <img src="/images/step4/bubble3.png" alt=""/> }
                      {count > 0 ? 
                        <div className="layer btn6" onClick={this.handleGameOver}>
                          <img src="/images/step4/btn2.png" alt=""/>
                        </div> :
                        <div className="layer btn6 btn6_last" onClick={this.handleGameOver}>
                          <img src="/images/step4/btn2-result.png" alt=""/>
                        </div>
                      }
                      {this.state.difference > 0 && <div className="bubble-value">{this.state.difference}<span>г</span></div>}
                      {count > 0 && <div className="bubble-value2"><i>{this.declOfNum(count,['остался','осталось','осталось'])}</i>{count}<span>{this.declOfNum(count,['продукт','продукта','продуктов'])}</span></div> }
                    </div>
                  }
                  {this.props.menu && !this.props.mobile && <Menu play={this.handlePlay} step={this.state.step} />}
                  {this.props.signup && !this.props.mobile && <Signup />}
                </div>
              }
              {showTop && 
                <div className="spacer spacer-bottom">
                  <img style={portrait ? {maxHeight: height} : {}} src={portrait ? '/images/mobile/spacer.png' : '/images/spacer.png'} alt=""/>
                  
                  {this.state.step === 10 && this.state.game === 5 &&
                    <div className="layer robot-hand">
                      <img src="/images/step4/robot-hand.png" alt=""/> 
                    </div>
                  }
                </div>
              }
            </Fragment>

          }
        </div>
        {this.props.cookie && <Cookie />}
        {this.props.menu && this.props.mobile && <Menu play={this.handlePlay} step={this.state.step} />}
        {this.props.signup && this.props.mobile && <Signup />}
        {this.props.postcard && <Postcard />}
        {this.props.postcard_success && <PostcardSuccess />}
        {this.props.rating && <Rating />}
        {this.props.gameover && <Gameover />}
        {this.props.signin && <SignIn result={this.state.result} />}
        {this.props.forgot_password && <ForgotPassword />}
        {this.props.signin_step2 && <WhereDoYouWotk />}

        {this.props.signin_success && <SignInSuccess />}
        {this.props.signup_success && <SignUpSuccess />}

        {this.props.error.open && <ErrorMessage message={this.props.error.message} close={this.props.closeError} />}
        {this.props.mobile && <CookieInfo cookieOpen={this.props.cookieOpen} />}

        {this.state.step === 10 && this.state.modalShow && <Modals />}
      </Fragment>
    )
  }

  initedGame = () => this.setState({initedGame: true, game: this.state.game+1})

  handleGameOver = () => {
    if (this.state.list.length < 6) return this.handleNext()
    const percent = this.state.list.reduce((acc, el) => acc + el.percent, 0)/this.state.list.length
    console.log(percent);
    this.setState({result: Math.round(percent * 100) / 100})
    this.props.gameoverOpen(Math.round(percent * 100) / 100)
    this.setState({list: []})
    this.handleNext()
  }


  togglePlay = () => this.setState({play: !this.state.play})

  toggleMenu = () => {
    if (this.props.signup) return this.props.menuClose()
    this.props.menu ? this.props.menuClose() : this.props.menuOpen()
  }

  handlePlay = () => {
    if (this.state.step < 4) return false
    clearTimeout(this.tid)
    this.setState({step: 10})
  }

  declOfNum = (number, titles) => {
    var cases = [2, 0, 1, 1, 1, 2]
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }


  get slider() {
    const {current, newCurrent, slider} = this.state
    return slider.map((item, i) => {
      const el = item
      // const el = 'i4'
      const dragHandlers = {
        onStop: this.dishClick,
        cancel: '.owl-carousel, .slick-slider, a, button',
        position: { x: parseInt(this.state.x), y: 0 },
        axis: 'x',
        bounds:'parent'
      }

      return (
        <Fragment key={i}>
          <div className={`layer paper ${current === i+1 ? 'to-bottom' : newCurrent === i+1 ? 'from-top' : 'hidden-dish' }`}>
            <img src={this.state.step === 10 ? `/images/paper/i${i+1}.png` : `/images/paper/${i+1}.png`} alt=""/>
          </div>  
          <div className={`layer ${el} ${current === i+1 ? 'to-bottom-dish' : newCurrent === i+1 ? 'from-top-dish current-element' : 'hidden-dish' }`}>
            <div>
              <img className="pice2" src={`/images/dishes/${el}.png`} alt=""/>
              <img className="layer-abs pice" src={`/images/dishes/${el}-2.png`} alt=""/>
              <div className="handle-width-counter" />
              {this.state.game > 1 && !this.props.mobile && <div className="handle-hover" onMouseMove={this.handleMove} value={el} onClick={this.dishClick}>
                <div className="layer cursor" style={{left: this.state.x}}>
                  <img src="/images/step2/cursor.svg" alt=""/>
                </div>
              </div>}
              {this.state.game > 1 && this.props.mobile &&   
                <div className="handle-hover" value={el} >
                  <DraggableCore {...dragHandlers}>
                    <div className="layer cursor">
                      <img src="/images/step2/cursor.svg" alt=""/>
                    </div>
                  </DraggableCore>
                </div>
              }
            </div>
          </div> 
          {this.state.game >= 4 && el === this.state.game_done && 
            <Fragment >
              <div className={`layer ${el} from-top-dish`}>
                <div>
                  <img src={`/images/dishes/${el}.png`} alt=""/>
                  {i !== 3 && <img className="layer-abs" src={`/images/dishes/${el}-2.png`} alt=""/>}
                  {this.state.game === 5 && <div className="handle-hover line">
                    <div className="layer cursor line" style={this.props.mobile ? {transform: `translateX(${this.state.x}px) rotate(5deg)`} : {left: this.state.x}}>
                      <img src="/images/step4/line.svg" alt=""/>
                    </div>
                    <div className="layer cursor robot-line" style={this.props.mobile ? {transform: `translateX(${this.state.ntc}px) rotate(5deg)`} : {left: this.state.ntc}}>
                      <img src="/images/step4/line.svg" style={{opacity:0}} alt=""/>
                      <img className="layer-abs" src="/images/step4/robot-line.svg" alt=""/>
                      <span className="layer line-icon"><img src="/images/step4/line-icon.svg" alt=""/></span>
                      <span className="layer line-icon2"><img src="/images/step4/line-icon.svg" alt=""/></span>
                    </div>
                  </div>}
                </div>
              </div> 
            </Fragment>
          }
        </Fragment>
      )
    })
  }

  dishClick = (ev, ui) => {
    const {newCurrent, needToСut, random ,weight} = this.state
    const el = this.props.mobile ? ev.target.parentElement.parentElement : ev.target
    const value = el.getAttribute('value')
    const rect = el.getBoundingClientRect()
    const el_rect = ev.target.getBoundingClientRect()

    const x = this.props.mobile ? ui.x : ev.clientX - rect.left
    const ntc = needToСut[newCurrent-1]
    // const ntc = Math.round(rect.width)
    const difference = Math.abs(Math.floor(ntc - x))

    let percent = 0
    if (x <= ntc) {
      percent = x*100/ntc
    }
    if (x > ntc) {
      percent = (ntc - (x - ntc))*100/ntc
      if (percent < 0) percent = 0
    }

    this.animateValue(x)
    this.setState({
      x, 
      difference,  
      ntc,
      game: this.state.game+1, 
      game_done: value,
      list: [...this.state.list, {name:value, difference, x, percent}]
    })
  }

  handleMove = ev => {
    const rect = ev.target.getBoundingClientRect()
    this.setState({x: ev.clientX - rect.left})
  }

  nextGameStep = () => this.setState({game: this.state.game+1})

  timer = null

  animateValue = (end) => {
    clearInterval(this.timer)
    clearTimeout(this.t3)
    let value = 0
    this.timer = setInterval(() => {
        value += 2
        this.setState({animateValue: value})
        if (value >= end) {
            clearInterval(this.timer)
        }
    }, 1)
  }

  msieversion = () => {
    if (document.documentMode || /Edge/.test(navigator.userAgent) || /Edg/.test(navigator.userAgent)) {
      return true
    }
    return false
  }
}

export default connect(
  (state) => ({
    portrait: state.portrait,
    mobile: state.mobile,
    height: state.height,
    postcard: state.modal.postcard.open,
    postcard_success: state.modal.postcard_success.open, 
    rating: state.modal.rating.open,
    gameover: state.modal.gameover.open,
    signin: state.modal.signin.open,
    error: state.modal.error,
    forgot_password: state.modal.forgot_password.open,
    signin_success: state.modal.signin_success.open,
    menu: state.modal.menu.open,
    signup: state.modal.signup.open,
    signup_success: state.modal.signup_success.open,
    signin_step2: state.modal.signin_step2.open,
    cookie: state.modal.cookie.open,
  }),
  { postcardOpen, ratingOpen, gameoverOpen, closeError, menuOpen, menuClose, cookieOpen, cookieClose }
)(layout(Home))
