import React from 'react'
import { connect } from 'react-redux'

function Footer({portrait}) {
  return (
    <div className={portrait ? 'footer' : 'footer footer-landscape'} >
      <div className="content">
          <div className="footer__icons">
            <a className="footer__ico footer__ico1" href="https://www.x5.ru/ru" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p1.svg`} alt="" />
            </a>
            <a className="footer__ico footer__ico2" href="https://5ka.ru/" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p2.svg`} alt="" />
            </a>
            <a className="footer__ico footer__ico3" href="https://karusel.ru/" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p3.svg`} alt="" />
            </a>
            <a className="footer__ico footer__ico4" href="https://www.perekrestok.ru/" target="_blank" rel="noopener noreferrer">
              <img src={`/images/icons/p4.svg`} alt="" />
            </a>
            {portrait ||
              <>
                <a className="footer__ico footer__ico5" href="https://www.x5.ru/ru/" target="_blank" rel="noopener noreferrer">
                  <img src={`/images/icons/p5.svg`} alt="" />
                </a>
                <a className="footer__ico footer__ico6" href="https://www.x5.ru/ru/" target="_blank" rel="noopener noreferrer">
                  <img src={`/images/icons/p7.svg`} alt="" />
                </a>
                <a className="footer__ico footer__ico7" href="https://5ka.ru/" target="_blank" rel="noopener noreferrer">
                  <img src={`/images/icons/p8.svg`} alt="" />
                </a>
                <a className="footer__ico footer__ico8" href="https://www.perekrestok.ru/" target="_blank" rel="noopener noreferrer">
                  <img src={`/images/icons/p9.svg`} alt="" />
                </a>
              </>
            }
          </div>

          {portrait &&
            <div className="footer__icons">
              <a className="footer__ico footer__ico5" href="https://www.x5.ru/ru/" target="_blank" rel="noopener noreferrer">
                <img src={`/images/icons/p5.svg`} alt="" />
              </a>
              <a className="footer__ico footer__ico6" href="https://www.x5.ru/ru/" target="_blank" rel="noopener noreferrer">
                <img src={`/images/icons/p7.svg`} alt="" />
              </a>
              <a className="footer__ico footer__ico7" href="https://5ka.ru/" target="_blank" rel="noopener noreferrer">
                <img src={`/images/icons/p8.svg`} alt="" />
              </a>
            </div>
          }
          {portrait &&
            <div className="footer__icons footer__icons--last">
              <a className="footer__ico footer__ico8" href="https://www.perekrestok.ru/" target="_blank" rel="noopener noreferrer">
                <img src={`/images/icons/p9.svg`} alt="" />
              </a>
            </div>
          }
          <div className="footer__text">
            Возникла проблема с порталом? {portrait && <br/>} <a href="mailto:ny@happy.x5.ru" target="_blank"> Напишите нам </a> , и мы всё исправим.
            <br/>
            © 2020 «Х5 Retail Group». Все права защищены.
          </div>
          <div>
            Сделано в{' '}
            <a href="http://www.amio.ru/" target="_blank" rel="noopener noreferrer">
              A M I O
            </a>
          </div>
      </div>
    </div>
  )
}


export default connect(
  (state) => ({
    portrait: state.portrait,
  }),
  {}
)(Footer)
