import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import Counter from '../common/counter'
import { forgotPasswordClose } from '../../redux/modules/modal'
import { forgotPassword, changePassword, counterReset } from '../../redux/modules/sign-in'

class ModalAlmostComplite extends Component {
  state = {
    triggerValidate: false,
    triggerValidate2: false,
    visible: false,
    email: {
      value: '',
      name: 'email',
      type: 'name',
      placeholder: 'Логин (E-mail)',
      validate: 'email',
      valid: false
    },
    code: {
      value: '',
      name: 'code',
      type: 'text',
      placeholder: 'Код',
      validate: 'text',
      valid: false
    },
    password: {
      value: '',
      name: 'password',
      type: 'password',
      placeholder: 'Пароль',
      validate: 'password',
      valid: false
    },
    confirmPassword: {
      value: '',
      name: 'confirmPassword',
      type: 'password',
      placeholder: 'Подтверждение пароля',
      validate: 'password',
      valid: false,
      compare: 'password'
    }
  }

  componentDidMount() {
    this.props.counterReset()
  }

  render() {
    return (
      <div className={this.props.mobile ? 'modal' : "modal message forgot-password"}>
        <div className="modal__overlay"></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal__body__inner">
              <div className="modal-close" onClick={this.props.forgotPasswordClose}></div>
              <div className="forgot-password__form">
                {this.props.success_code && 
                  <div className="forgot-password__title-small">
                    Для воссстановления пароля <br />
                    введите код, отправленный вам <br />
                    на электронную почту, <br />и новый пароль
                  </div>
                }
                {!this.props.success_code && !this.props.success && <div className="forgot-password__title">Забыли пароль?</div>}
                  
                {this.props.success && <div className="forgot-password__title">Ваш пароль <br/> успешно изменен.</div> }

                {this.props.error && <div className="error-message">{this.props.error}</div>}

                {this.props.success_code &&
                  <Fragment>
                    <div className=""></div>
                    <InputField triggerValidate={this.state.triggerValidate2} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.code} />
                    <InputField triggerValidate={this.state.triggerValidate2} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.password} />
                    <InputField triggerValidate={this.state.triggerValidate2} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.confirmPassword} />
                    <div className="form-counter">
                      Код действителен в течении <Counter callback={this.props.counterReset} /> сек.
                    </div>
                    <div className="forgot-password__btn2" onClick={this.handleSend2} />
                  </Fragment>
                }
                {!this.props.success_code && !this.props.success && 
                  <Fragment>
                    <InputField triggerValidate={this.state.triggerValidate} setFieldValid={this.setFieldValid} change={this.handleChange} {...this.state.email} />
                    <div className="forgot-password__btn" onClick={this.handleSend} />
                  </Fragment>
                }
                {this.props.success && <div className="message__btn" onClick={this.props.forgotPasswordClose} />}
              </div>
            </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.state.email.valid || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('login', this.state.email.value)

    await this.props.forgotPassword(formData)
    this.setState({ pending: false })
  }

  handleSend2 = async () => {
    this.setState({ triggerValidate2: true })
    if (!this.formIsValid() || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('login', this.state.email.value)
    formData.append('code', this.state.code.value)
    formData.append('password', this.state.password.value)
    formData.append('confirmPassword', this.state.confirmPassword.value)
    await this.props.changePassword(formData)
    this.setState({ pending: false })
  }

  formIsValid = () => {
    return Object.keys(this.state).every((name) => {
      const field = this.state[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      [name]: { ...this.state[name], valid }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({
      [name]: { ...this.state[name], value }
    })
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    error: state.auth.forgot_password.error,
    success_code: state.auth.forgot_password.success_code,
    success: state.auth.forgot_password.success
  }),
  { forgotPasswordClose, forgotPassword, counterReset, changePassword }
)(ModalAlmostComplite)
