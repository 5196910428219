import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";
import signInReducer, { moduleName as signInModule } from './modules/sign-in'
import loadingReducer, { moduleName as loadingModule } from './modules/loading'
import modalReducer, { moduleName as modalModule } from './modules/modal'
import mobileReducer, { moduleName as mobileModule } from './modules/mobile'
import heightReducer, { moduleName as heightModule } from './modules/height'
import portraitReducer, { moduleName as portraitModule } from './modules/portrait'


export default combineReducers({
  router: connectRouter(history),
  [signInModule]: signInReducer,
  [loadingModule]: loadingReducer,
  [modalModule]: modalReducer,
  [mobileModule]: mobileReducer,
  [heightModule]: heightReducer,
  [portraitModule]: portraitReducer,
});
