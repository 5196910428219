import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import 'simplebar/dist/simplebar.min.css';
import '../css/app.scss'
import '../css/mobile.scss'

import PrivateRoute from './common/private-router'
import Main from './main'
import { checkSession } from '../redux/modules/sign-in'
import { setMobile } from '../redux/modules/mobile'
import { setHeight } from '../redux/modules/height'
import { setPortrait } from '../redux/modules/portrait'
import { cookieOpen } from '../redux/modules/modal'

import Loader from './common/loader'

class App extends Component {
  state = {
    loading: true
  }
  componentDidMount() {
    const img = new Image()
    img.src = '/images/loader.png'
    img.onload = () => this.setState({ loading: false})
  
    this.props.checkSession(localStorage.getItem('user'))
    this.props.setMobile(window.innerWidth <= 1000)
    this.props.setPortrait(window.innerHeight - window.innerWidth > 0)
    this.props.setHeight(window.innerHeight)
    window.addEventListener('resize', this._onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize)
  }

  render() {
    const {checked, loading} = this.props
    if (this.state.loading) return null
    if (!checked || loading) return <Loader />
    return (
      <div>
        <YMInitializer accounts={[51203048]} />
        <Switch>
          <Route path="/" exact={true}  component={this.getMain} />
        </Switch>
      </div>
    )
  }

  getMain = ({ match }) => {
    if (match.isExact) return <Main />
  }

  _onResize = () => {
    const mobile = window.innerWidth <= 1000
    const portrait = window.innerHeight - window.innerWidth > 0
    const height = window.innerHeight

    if ((mobile && !this.props.mobile) || (!mobile && this.props.mobile)) {
      this.props.setMobile(mobile)
    }
    if ((portrait && !this.props.portrait) || (!portrait && this.props.portrait)) {
      this.props.setPortrait(portrait)
    }
    if (Math.abs(height - this.props.height) > 100) {
      this.props.setHeight(height)
    }
  }

}

export default connect(
  (state) => ({
    mobile: state.mobile,
    portrait: state.portrait,
    height: state.height,
    // user: state.auth.user,
    checked: state.auth.checkedSession,
    loading: state.loading
  }),
  { checkSession, setMobile, setHeight, setPortrait, cookieOpen }
)(App)
