import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {signinClose, forgotPasswordOpen, signupOpen, menuClose} from '../../../redux/modules/modal'
import {signIn} from '../../../redux/modules/sign-in'

import InputField from '../../common/input-field'
import Social from '../../common/social'

class signin extends Component {
  state = {
    triggerValidate: false,
    email: {
      value: '',
      name: 'email',
      type: 'name',
      // placeholder: 'Логин (E-mail)',
      placeholder: 'Логин (E-mail)',
      validate: 'text',
      valid: false
    },
    password: {
      value: '',
      name: 'password',
      type: 'password',
      placeholder: 'Пароль',
      validate: 'password',
      valid: false
    },
    pending: false
  }

  render() {
    return (
      <div className="signin__body">
        <div className="signin__title">
          <span>Авторизация <br/> через</span>
          <span className="id-icon"></span>
        </div>
        <div className="signin__form">
          <InputField 
            triggerValidate={this.state.triggerValidate} 
            setFieldValid={this.setFieldValid} 
            change={this.handleChange} 
            {...this.state.email} 
          />
          <InputField 
            triggerValidate={this.state.triggerValidate} 
            setFieldValid={this.setFieldValid} 
            change={this.handleChange} 
            {...this.state.password} 
          />
          {this.props.small ? 
            <div className="auth-links">
              <a href="#" onClick={this.handleSignUpOpen}>
                Получить Happy ID
              </a>
              <a href="#" onClick={this.handleForgotPassword}>
                Забыли пароль?
              </a>
            </div>
            :
            <a href="#" onClick={this.handleForgotPassword}>
              Забыли пароль?
            </a>
          }
          <div className="signin__btn" onClick={this.handleSend} />
          <div className="signin__hr"></div>
          <div className="signin__title2">Авторизация через соцсети</div>
          <Social />
          {this.props.menu && 
            <div className="sign-up-btn">
              <div className="signin__hr"></div>
              <div className="signin__title">
                <span>Зарегистрироваться в</span>
                <span className="id-icon"></span>
              </div>
              <div className="signup__btn" onClick={this.handleSignUp} />
            </div>
          }
        </div>

      </div>
    )
  }

  handleSignUp = () => {
    this.props.menuClose()
    this.props.signupOpen()
  }

  handleSignUpOpen = (e) => {
    e.preventDefault()
    this.props.signinClose()
    this.props.signupOpen()
  }

  handleForgotPassword = (e) => {
    e.preventDefault()
    this.props.signinClose()
    this.props.forgotPasswordOpen()
  }

  handleSend = async () => {
    this.setState({ triggerValidate: true })
    if (!this.formIsValid() || this.state.pending) return false
    this.setState({ pending: true })

    const formData = new FormData()
    formData.append('login', this.state.email.value)
    formData.append('password', this.state.password.value)
    formData.append('remember', 'Y')

    await this.props.signIn(formData, this.props.result)
    this.setState({ pending: false })
    if (!this.props.error) this.props.signinClose()
  }

  formIsValid = () => {
    return Object.keys(this.state).every((name) => {
      const field = this.state[name]
      if (!field.validate) return true
      return field.valid
    })
  }

  setFieldValid = (name, valid) =>
    this.setState({
      [name]: { ...this.state[name], valid }
    })

  handleChange = (ev) => {
    const { name, value } = ev.target
    this.setState({
      [name]: { ...this.state[name], value }
    })
  }
}

export default connect(
  (state) => ({
    error: state.modal.error.open
  }),
  {signinClose, signIn, forgotPasswordOpen, signupOpen, menuClose}
)(signin)
