import React, { Component } from 'react'
import { connect } from 'react-redux'
import { postcardSuccessClose } from '../../redux/modules/modal'

class SocialSuccess extends Component {
  state = {
    visible: false
  }

  render() {
    return (
      <div className={this.props.mobile ? 'modal message-mobile': 'modal message'}>
        <div className="modal__overlay" onClick={this.props.postcardSuccessClose}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.postcardSuccessClose}></div>
            <div className="mob-pos">
              <div className="message__title2">
               Вы успешно отправили открытку!
              </div>
              <div className="message__btn" onClick={this.props.postcardSuccessClose}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
  }),
  { postcardSuccessClose }
)(SocialSuccess)
