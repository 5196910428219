import React, { Component } from 'react'
import { connect } from 'react-redux'
import {ratingClose} from '../../redux/modules/modal'
import { getRating } from '../../redux/modules/sign-in'
import SimpleBar from 'simplebar-react';

class Rating extends Component {
  componentDidMount() {
    document.querySelector('body').style = "overflow:hidden"
    this.props.getRating()
  }
  componentWillUnmount() {
    document.querySelector('body').style = '' 
  }

  render() {
    return (
      <div className={this.props.mobile ? 'modal rating-mobile': 'modal rating'}>
        <div className="modal__overlay" onClick={this.props.ratingClose}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.props.ratingClose}></div>
            <div className="rating__title" />
            <div className="rating__image" />
            <div className="rating__body">
              {this.props.mobile ? this.table : 
                <SimpleBar style={{ maxHeight: 365 }}>
                  {this.table}
                </SimpleBar>
              }
            </div>
            <div className="rating__btn" onClick={this.props.ratingClose} />
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  get table() {
    return (
      <table className="rating-table">
        <tbody>
          {this.rows}
        </tbody>
      </table>
    )
  }

  get rows() {
    return this.props.rating.map((el, i) => <tr key={i}>
        <td><span>{i+1}</span></td>
        <td><span>{el.name}</span></td>
        <td><span>{el.logo && <img src={`/images/icons/${el.logo}.svg`} alt=""/>}</span></td>
        <td><span>{el.result}%</span></td>
      </tr>)
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    rating: state.auth.rating
  }),
  {ratingClose, getRating}
)(Rating)
