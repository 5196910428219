import React, { Component } from 'react'
import { connect } from 'react-redux'
import {cookieClose} from '../../redux/modules/modal'
import { getRating } from '../../redux/modules/sign-in'
import SimpleBar from 'simplebar-react';

class Rating extends Component {
  componentDidMount() {
    // document.querySelector('body').style = "overflow:hidden"
    document.querySelector('#root').classList.add('cookie-page')
  }
  componentWillUnmount() {
    // document.querySelector('body').style = '' 
    document.querySelector('#root').classList.remove('cookie-page')
  }

  render() {
    if (this.props.mobile) return this.mobile
    return (
      <div className="cookie">
        <div className="modal__body">
          <div>
            <SimpleBar style={{ maxHeight: this.props.mobile ? 478 :350 }}>
              {this.body}
            </SimpleBar>
          </div>
        </div>
      </div>
    )
  }

  get mobile() {
    return (
      <div className="modal cookie-mobile">
        <div className="logo"></div>
        <div className="modal__container">
          <div className="modal__body">
              {this.body}

              <div className="layer cookie-lnk-mobile" onClick={this.props.cookieClose}>
                <img src="/images/cookie/btn.png" alt=""/>
              </div>
          </div>
          <div className="modal-helper"></div>
        </div>
      </div>
    )
  }

  get body() {
    return (
    <div className="cookie-mobile__body">
      <div className="cookie-title">Что такое coockie?<br/> И почему сайты предупреждают о них?</div>
      <p>Если простыми словами, то сайты, которые вы посещаете, делают у себя пометки, что к ним зашел такой-то пользователь сети интернет (вы). В следующий раз, когда вы снова к ним зайдете, они вас узнают и могут показать ваш аккаунт, в который вы ранее входили, чтобы вам не входить каждый раз (в том числе, после первого входа).</p>
      <p>Именно поэтому, когда вы зайдете на этот сайт завтра, то он вас узнает, и вам не надо будет выполнять уже пройденные задания и заполнять данные повторно</p>
      <p>А предупреждают, потому что это сбор пользовательских данных. В большинстве стран мира такой сбор регулируется законом, один из которых – оповещать пользователя сайта, что сайт собирает и хранит эти самые кукис.</p>
    </div>
    )
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    rating: state.auth.rating
  }),
  {cookieClose}
)(Rating)
