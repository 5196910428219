import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signUpSuccessClose } from '../../redux/modules/modal'
import { getProfile } from '../../redux/modules/sign-in'

class SocialSuccess extends Component {
  state = {
    visible: false
  }

  render() {
    return (
      <div className={this.props.mobile ? 'modal message-mobile': 'modal message'}>
        <div className="modal__overlay" onClick={this.handleClose}></div>
        <div className="modal__container">
          <div className="modal__body">
            <div className="modal-close" onClick={this.handleClose}></div>
            <div className="mob-pos">
              <div className="message__title2">
                Поздравляем! <br/> Вы успешно зарегистрировались!
              </div>
              <div className="modal-content">
                С этими регистрационными данными вы сможете <br /> авторизовываться на любых проектах Happy.
              </div>
              <div className="share">
                <div>
                  С друзьями всегда веселее. <br />
                  Не забудьте поделиться с ними ссылкой
                  <div className="social social2">
                    <a href={`http://vkontakte.ru/share.php?url=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon VKontakte-icon" />
                    <a href={`http://www.facebook.com/sharer.php?s=100&p[url]=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon Facebook-icon" />
                    <a href={`https://connect.ok.ru/offer?url=${encodeURIComponent(document.location.href)}`} target="_blank" className="social-icon Odnoklassniki-icon" />
                  </div>
                </div>
              </div>
              <div className="message__btn" onClick={this.handleClose}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleClose = async() => {
    this.props.signUpSuccessClose()
    this.props.getProfile(this.props.result)
  }
}

export default connect(
  (state) => ({
    mobile: state.mobile,
    result: state.modal.result,
    user: state.auth.user
  }),
  { signUpSuccessClose, getProfile }
)(SocialSuccess)
