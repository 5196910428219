import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getLinks } from '../../redux/modules/sign-in'

class Social extends Component {
  static propTypes = {
    links: PropTypes.array.isRequired
  }

  componentDidMount() {
    if (!this.props.links.length) this.props.getLinks()
  }

  render() {
    if (!this.props.links.length) return null
    return (
      <div className="social">
        {this.icons}
      </div>
    )
  }

  get icons() {
    return this.props.links.map((el, i) => <div key={i} onClick={() => this.handleClick(el.onclick)} className={`social-icon ${el.auth_service_id}-icon`} />)
  }

  handleClick = (url) => (window.GLOBAL_SOCIAL_WINDOW = window.open(url, '', 'status=no,scrollbars=yes,resizable=yes,width=800,height=800,top=50,left=50'))
}

export default connect(
  (state) => ({
    links: state.auth.links
  }),
  { getLinks }
)(Social)
