import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import reducer from "./reducer";
import history from "./history";

const enhancer = applyMiddleware(
  thunk,
  routerMiddleware(history),
);

const store = createStore(reducer, enhancer);

//dev only
window.store = store;

export default store;
